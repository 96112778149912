
import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref,
} from 'vue';
import ShippingHeader from '@/modules/floortrak/view/shipping/components/ShippingHeader.vue';
import AddTransactionModal from '@/modules/floortrak/view/shipping/components/AddTransactionModal.vue';
import ShippingCloseoutModal from '@/modules/floortrak/view/shipping/components/ShippingCloseoutModal.vue';
import TransactionItemEventBus from '@/modules/floortrak/view/shared/transaction-item-event-bus';
import TagListener from '@/modules/floortrak/services/TagListener';
import ShippingService from '@/modules/floortrak/services/ShippingService';
import { useNotification } from '@/composable/useNotifications';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import floorTrakStore from '@/modules/floortrak/store/FloorTrakStore';
import Shipment from '@/domain/Shipment';
import { ItemType } from '@/domain/enums/ItemType';
import router from '@/router';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import useValidator from '@/validation/useValidator';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { CoreStore } from '@/store/CoreStore';
import TrackedItem from '@/domain/TrackedItem';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import FloortrakShippingTagScanHandler from '@/services/tag-scanning/scan-handler/FloortrakShippingTagScanHandler';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import Screen from '@/components/layout/Screen.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { FormValidationKey, ValidationResult } from '@/validation/types';
import SafetyInspectionQuestionnaire from '@/modules/floortrak/view/shared/components/SafetyInspectionQuestionnaire.vue';
import SafetyInspection from '@/domain/SafetyInspection';
import SafetyInspectionType from '@/domain/enums/SafetyInspectionType';
import Carrier from '@/domain/Carrier';
import SafetyInspectionService from '@/services/SafetyInspectionService';
import ProductionPartLoadInventory from '@/domain/ProductionPartLoadInventory';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import FloorLocation from '@/domain/FloorLocation';
import FloorLocationService from '@/services/FloorLocationService';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';

interface CardData {
    floorLocation: string;
    trackedItem: TrackedItem;
    scanned: number;
    toScan: number;
}

interface PickPlanTableData {
    tag: TrackedItem;
    required: boolean;
    picked: boolean;
    action: undefined;
}

type State = {
    show: boolean;
    originalPostLoadInspectionPassed: boolean;
    preloadSafetyInspection: SafetyInspection;
    postloadSafetyInspection?: SafetyInspection;
    carriers: Carrier[];
    showPreLoadSafetyInspection: boolean;
    showPostLoadSafetyInspection: boolean;
    showSafetyChecklist: boolean;
    showEquipmentInspection: boolean;
    itemsNotInPickPlan: Array<TrackedItem>;
    validationResult: ValidationResult<Shipment> | undefined;
    loading: boolean;
    pickPlan: Array<TrackedItem>;
    inventoryFloorLocations: Array<ProductionPartLoadInventory>;
    availableFloorLocations: Array<FloorLocation>;
    showPickTable: boolean;
    selectedFloorLocationPickTable: Array<PickPlanTableData>;
    selectedFloorLocationPickTitle: string;
    selectedFloorLocationPickSubtitle: string;
};

export default defineComponent({
    name: 'shipping-warehouse',
    components: {
        BCol,
        BRow,
        Screen,
        BButton,
        BModal,
        ShippingHeader,
        SmartTrakFooter,
        SafetyInspectionQuestionnaire,
        BDropdown,
        BDropdownItem,
        TrackedItemTagModal,
        BTable,
    },

    setup() {
        const shippingService = new ShippingService();
        const { shippingStore } = floorTrakStore.getInstance();
        const { carrierStore, profileStore } = CoreStore.getInstance();
        const loading = useLoading();
        const supplierShipmentService = new SupplierShipmentService();
        const shippingModalRef = ref<InstanceType<typeof ShippingCloseoutModal>>();
        const addTransactionModalRef = ref<InstanceType<typeof AddTransactionModal>>();
        const saveOnlyValidationKey = 'ft-shipping-shipment-save-only';
        const moveOrShipValidationKey = 'ft-shipping-shipment';
        const safetyInspectionService = new SafetyInspectionService();
        const { forkliftCertified, certExpirationDate } = CoreStore.getInstance().profileStore;
        const floorLocationService = new FloorLocationService();

        const originalShipmentStringified = ref<string>('');

        const isDirty = computed((): boolean => JSON.stringify(shippingStore.shipment) !== originalShipmentStringified.value);

        const disableDropdown = computed((): boolean => !shippingStore.canShipNow || shippingStore.readonly);

        const { showError } = useNotification();

        const { confirm } = useDialogBox();

        const shippingHeaderTitle = computed(() => {
            if (shippingStore.isExisting) {
                // eslint-disable-next-line max-len
                return `${getTitleCaseTranslation('core.common.shipmentWithIdFrom', shippingStore.shipment.id ? shippingStore.shipment.id.toString() : '')} ${
                    shippingStore.activeTransaction.fromLocation.name
                }`;
            }
            return `${getTitleCaseTranslation('core.common.newShipmentFrom')} ${shippingStore.activeTransaction?.fromLocation.name || ''}`;
        });

        const state = reactive<State>({
            originalPostLoadInspectionPassed: false,
            preloadSafetyInspection: new SafetyInspection(),
            carriers: carrierStore.carriers,
            show: false,
            showPreLoadSafetyInspection: false,
            showPostLoadSafetyInspection: false,
            showSafetyChecklist: false,
            showEquipmentInspection: true,
            itemsNotInPickPlan: [],
            validationResult: undefined,
            loading: false,
            pickPlan: [],
            inventoryFloorLocations: [],
            availableFloorLocations: [],
            showPickTable: false,
            selectedFloorLocationPickTable: [],
            selectedFloorLocationPickTitle: '',
            selectedFloorLocationPickSubtitle: '',
        });

        function getFloorLocationName(floorLocationId: number): string {
            return state.availableFloorLocations.find((fl) => fl.id === floorLocationId)?.name ?? '';
        }

        function getScannedTotalForTrackedItem(floorLocationId: number): number {
            let totalScanned = 0;
            shippingStore.activeTransaction.transactionLines.forEach((transactionLine) => {
                const scannedItems = transactionLine.trackedItemList;
                scannedItems.forEach((trackedItem) => {
                    const floorLocationForTrackedItem = state.inventoryFloorLocations.find((fl) => fl.trackedItemId === trackedItem.id && fl.floorLocationId === floorLocationId);
                    if (floorLocationForTrackedItem) {
                        totalScanned++;
                    }
                });
            });

            return totalScanned;
        }

        function getUnplannedItemsInFloorLocation(card: CardData) {
            const floorLocation = state.availableFloorLocations.find((fl) => fl.name === card.floorLocation);
            if (floorLocation) {
                const inventoryInFloorLocation = state.inventoryFloorLocations.filter((x) => x.floorLocationId === floorLocation.id);
                const x = state.itemsNotInPickPlan.filter((y) => y.item.shortName === card.trackedItem.item.shortName && inventoryInFloorLocation.some((z) => z.trackedItemId === y.id));

                return x.length;
            }
            return 0;
        }

        const cards = computed(
            (): Array<CardData> => {
                if (state.pickPlan.length > 0 && state.inventoryFloorLocations.length > 0) {
                    const groupedData: Array<CardData> = [];
                    state.inventoryFloorLocations.forEach((ppli) => {
                        const floorLocationName = getFloorLocationName(ppli.floorLocationId);
                        const existingCardIndex = groupedData.findIndex((card) => card.floorLocation === floorLocationName);

                        if (existingCardIndex >= 0) {
                            groupedData[existingCardIndex].toScan++;
                        } else {
                            const trackedItem = state.pickPlan.find((x) => x.id === ppli.trackedItemId)!;
                            const newCard: CardData = {
                                floorLocation: floorLocationName,
                                trackedItem,
                                scanned: getScannedTotalForTrackedItem(ppli.floorLocationId),
                                toScan: 1,
                            };

                            groupedData.push(newCard);
                        }
                    });
                    return groupedData;
                }
                return [];
            },
        );

        const fields: Array<BTableField<PickPlanTableData & { action: string }>> = [
            {
                key: 'tag',
                width: '150px',
                label: getTitleCaseTranslation('core.common.tag'),
            },
            {
                key: 'required',
                width: '75px',
                label: getTitleCaseTranslation('core.common.required'),
            },
            {
                key: 'picked',
                width: '75px',
                label: getTitleCaseTranslation('core.common.picked'),
            },
            {
                key: 'action',
                width: '75px',
                label: getTitleCaseTranslation('core.common.action'),
            },
        ];

        function getPickTableDataForCard(card: CardData) {
            const floorLocation = state.availableFloorLocations.find((fl) => fl.name === card.floorLocation);

            if (floorLocation) {
                const inventoryInFloorLocation = state.inventoryFloorLocations.filter((x) => x.floorLocationId === floorLocation.id);
                const tableData: Array<PickPlanTableData> = [];

                inventoryInFloorLocation.forEach((ppli) => {
                    const trackedItem = state.pickPlan.find((ti) => ti.id === ppli.trackedItemId);
                    const tagsAlreadyScanned = shippingStore.transactionArray.map((transaction) => transaction.tagList).flat();
                    if (trackedItem) {
                        const tableEntry: PickPlanTableData = {
                            tag: trackedItem,
                            required: true,
                            picked: tagsAlreadyScanned.some((x) => x === trackedItem.barcode),
                            action: undefined,
                        };

                        tableData.push(tableEntry);
                    }
                });

                state.selectedFloorLocationPickTable = tableData;
                state.selectedFloorLocationPickTitle = `${card.floorLocation} - ${card.trackedItem.item.shortName}`;
                state.selectedFloorLocationPickSubtitle = `${card.trackedItem.item.name}`;
            }
        }

        function getCardByBarcode(trackedItemBarcode: string) {
            const trackedItem = state.pickPlan.find((ti) => ti.barcode === trackedItemBarcode);
            const floorLocationForTrackedItem = state.inventoryFloorLocations.find((ppli) => ppli.trackedItemId === trackedItem?.id);

            if (floorLocationForTrackedItem) {
                const floorLocationName = getFloorLocationName(floorLocationForTrackedItem.floorLocationId);
                return cards.value.find((card) => card.floorLocation === floorLocationName);
            }
            return undefined;
        }

        async function addTag(trackedItem: TrackedItem) {
            if (trackedItem.deleted) {
                useNotification().showError(getTranslation('core.validation.barcodeDeleted'));
            } else {
                let addToTransaction = true;
                let notInPickPlan = false;

                if (!state.pickPlan.some((x) => x.id === trackedItem.id)) {
                    addToTransaction = await confirm({
                        title: getTitleCaseTranslation('core.validation.tagNotOnPickList'),
                        message: getTranslation('core.validation.areYouSureInShipment'),
                        vHtml: true,
                    });

                    notInPickPlan = true;
                }
                if (addToTransaction) {
                    shippingStore.activeTransaction.addTrackedItem(trackedItem);

                    if (notInPickPlan) {
                        state.itemsNotInPickPlan.push(trackedItem);
                    }
                }
            }

            const card = getCardByBarcode(trackedItem.barcode);
            if (card) {
                getPickTableDataForCard(card);
            }
        }

        function removeTag(trackedItem: TrackedItem) {
            shippingStore.activeTransaction.removeTrackedItemManagedWarehouse(trackedItem);

            const index = state.itemsNotInPickPlan.findIndex((x) => x.id === trackedItem.id);
            if (index >= 0) {
                state.itemsNotInPickPlan.splice(index, 1);
            }

            const card = getCardByBarcode(trackedItem.barcode);
            if (card) {
                getPickTableDataForCard(card);
            }
        }

        async function myTagCallback(barcodes: Array<string>) {
            if (shippingStore.readonly) {
                useNotification().showWarning(getTranslation('core.validation.cannotAddMoreItemsToShipment'));
            } else {
                const tagsAlreadyScanned = shippingStore.transactionArray.map((transaction) => transaction.tagList).flat();
                const scanHandler = new FloortrakShippingTagScanHandler(shippingStore.activeTransaction, tagsAlreadyScanned, addTag);
                await scanHandler.performSearch(barcodes);
            }
        }

        const tagListener = new TagListener(myTagCallback);

        function routeContainsValidTransactionId(): boolean {
            return shippingStore.transactionArray.find((tr) => tr.transactionNumber === router.currentRoute.value.params.transactionNumber) !== null;
        }

        function transactionLoadedFromSearchPage(): boolean {
            return shippingStore.transactionArray.length > 0 && routeContainsValidTransactionId();
        }

        async function findTransactionByUrl() {
            const transactionNumber = parseInt(router.currentRoute.value.params.transactionNumber as string, 10);

            try {
                const response = await shippingService.lookupShipmentByTransactionNumberAndFromLocation(transactionNumber, profileStore.userLocation.id);
                if (response.success) {
                    shippingStore.resetTransactionArray();
                    shippingStore.shipment = new Shipment(response.shipment);
                    shippingStore.activeIndex = 0;
                    state.show = true;
                } else {
                    showError(getTranslation('core.validation.errorLookingUpShipment'));
                    await router.push({ name: FloorTrakRouteTypes.SHIPPING.SEARCH });
                }
            } catch (err) {
                await router.push({ name: FloorTrakRouteTypes.SHIPPING.SEARCH });
            }
        }

        async function initShipment() {
            if (transactionLoadedFromSearchPage()) {
                shippingStore.shipment.fromLocation = shippingStore.activeTransaction.fromLocation;
                state.show = true;
            } else {
                await findTransactionByUrl();
                shippingStore.shipment.fromLocation = shippingStore.activeTransaction.fromLocation;
            }
            const preLoadInspectionExists = await safetyInspectionService.trailerInspectionExistsForShipment(shippingStore.shipment.id!);

            state.showSafetyChecklist = preLoadInspectionExists;
            state.showPreLoadSafetyInspection = !preLoadInspectionExists;

            if (preLoadInspectionExists) {
                state.originalPostLoadInspectionPassed = await safetyInspectionService.trailerInspectionOfTypePassesForShipment(shippingStore.shipment.id!, SafetyInspectionType.PostLoad);
            }
        }

        onBeforeMount(async () => {
            if (!forkliftCertified) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationMissing'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            } else if (!certExpirationDate || new Date(certExpirationDate) < new Date(Date.now())) {
                await confirm({
                    title: getTitleCaseTranslation('core.validation.forkliftCertificationHeader'),
                    message: getTranslation('core.validation.forkliftCertificationExpired'),
                    vHtml: true,
                });

                router.push({ name: FloorTrakRouteTypes.HOME });
            }
            tagListener.startListener();
            await initShipment();
        });

        onMounted(async () => {
            state.pickPlan = await shippingService.getPickPlanForShipment(shippingStore.shipment.id!);
            state.inventoryFloorLocations = await shippingService.getProductionPartLoadInventoryForDirectedPick(state.pickPlan.map((x) => x.id));
            const floorLocations = await floorLocationService.getFloorLocationsForLocation(shippingStore.activeTransaction.fromLocation);
            if (floorLocations.success) {
                state.availableFloorLocations = floorLocations.floorLocations;
            }

            shippingStore.activeTransaction.transactionLines.forEach((transactionLine) => {
                const scannedItems = transactionLine.trackedItemList;
                state.itemsNotInPickPlan = scannedItems.filter((trackedItem) => !state.pickPlan.some((item) => item.id === trackedItem.id));
            });
        });

        onBeforeUnmount(() => {
            tagListener.stopListener();
        });

        function validShipment(validationKey: FormValidationKey): boolean {
            const { validateForm, validationResult } = useValidator<Shipment>(validationKey);
            validateForm(shippingStore.shipment);
            state.validationResult = validationResult;
            return validationResult.isValid;
        }

        async function moveTransactionsToPicked(shipmentId: number): Promise<{ shipment?: Shipment }> {
            const { shipment } = await shippingService.moveTransactionsToPicked(shipmentId);
            return { shipment };
        }

        function closeShippingModal() {
            shippingModalRef.value?.close();
        }

        async function updateExistingShipment(validationKey: FormValidationKey): Promise<boolean> {
            let success = false;
            if (validShipment(validationKey)) {
                const { shipment } = await shippingService.updateExistingShipment(shippingStore.shipment);
                if (shipment) {
                    shippingStore.shipment = new Shipment(shipment);
                    originalShipmentStringified.value = JSON.stringify(shipment);
                    if (shippingStore.shipment.id) {
                        const { shipment: newShipment } = await moveTransactionsToPicked(shippingStore.shipment.id);
                        if (newShipment) {
                            shippingStore.shipment = new Shipment(newShipment);
                            originalShipmentStringified.value = JSON.stringify(newShipment);
                            useNotification().showSuccess(getTitleCaseTranslation('core.domain.shipmentSaved'));
                            success = true;
                        }
                    }
                } else {
                    useNotification().showError(`${getTranslation('core.validation.unableToSaveShipment')} ${shippingStore.shipment.id}`);
                }
            }
            return success;
        }

        async function saveAndExit() {
            loading.setLoading(true);
            closeShippingModal();
            if (shippingStore.isExisting) {
                await updateExistingShipment(saveOnlyValidationKey);
            }
            loading.setLoading(false);

            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        async function saveBeforeAction(): Promise<boolean> {
            let success = false;
            state.loading = true;
            if (isDirty.value || !shippingStore.shipment.id) {
                loading.setLoading(true);
                success = await updateExistingShipment(moveOrShipValidationKey);
            } else if (validShipment(moveOrShipValidationKey) && shippingStore.shipment.id) {
                loading.setLoading(true);
                const { shipment } = await moveTransactionsToPicked(shippingStore.shipment.id);
                if (shipment) {
                    shippingStore.shipment = new Shipment(shipment);
                    success = true;
                }
                originalShipmentStringified.value = JSON.stringify(shippingStore.shipment);
            }

            state.loading = false;
            loading.setLoading(false);
            return success;
        }

        // await the download then close the modal after re-route when complete
        async function getShipmentReceipt(shipmentId: number) {
            await shippingService.moveProductionPartLoadInventoriesToHistory(shippingStore.activeTransaction.id);

            shippingModalRef.value?.startDownload();
            await supplierShipmentService.getShipmentReceipt(shipmentId);
            closeShippingModal();

            shippingStore.resetTransactionArray();
            await router.push({ name: FloorTrakRouteTypes.SHIPPING.SEARCH });
        }

        async function moveShipmentToInTransit() {
            const shipmentId = shippingStore.shipment.id ?? 0;
            const response = await supplierShipmentService.shipNow(shipmentId);

            if (response) {
                await getShipmentReceipt(shipmentId);
            } else {
                closeShippingModal();
            }
        }

        async function checkLineItemsForShipping() {
            if (state.pickPlan.length > shippingStore.activeTransaction.tagList.length) {
                return confirm({
                    title: getTranslation('core.validation.pickPlanNotCompleted'),
                    message: getTranslation('core.validation.notAllRequiredItems'),
                    vHtml: true,
                });
            }

            return true;
        }

        async function moveTransactionsToHeld() {
            const shipmentId = shippingStore.shipment.id ?? 0;
            const transactionsMoved = await supplierShipmentService.moveTransactionsToHeld(shipmentId);

            if (transactionsMoved) {
                await getShipmentReceipt(shipmentId);
            } else {
                closeShippingModal();
            }
        }

        async function moveToYard() {
            loading.setLoading(true);
            if (await saveBeforeAction() && await checkLineItemsForShipping()) {
                await moveTransactionsToHeld();
            }
            loading.setLoading(false);
        }

        async function shipNow() {
            loading.setLoading(true);
            if (await saveBeforeAction() && await checkLineItemsForShipping()) {
                await moveShipmentToInTransit();
            }
            loading.setLoading(false);
        }

        function passedPreloadSafetyInspection(inspection: SafetyInspection) {
            state.preloadSafetyInspection = inspection;
        }

        async function resetPostloadSafetyInspection(inspection: SafetyInspection) {
            state.postloadSafetyInspection = inspection;
        }

        function cancelSafetyInspection() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function showPickTable(card: CardData) {
            state.showPickTable = true;
            getPickTableDataForCard(card);
        }

        function closePickTable() {
            state.showPickTable = false;
        }

        return {
            state,
            shippingStore,
            ItemType,
            shippingHeaderTitle,
            shipNow,
            saveAndExit,
            moveToYard,
            shippingModalRef,
            addTransactionModalRef,
            getTitleCaseTranslation,
            getTranslation,
            SafetyInspectionType,
            passedPreloadSafetyInspection,
            cancelSafetyInspection,
            resetPostloadSafetyInspection,
            saveBeforeAction,
            cards,
            showPickTable,
            closePickTable,
            fields,
            removeTag,
            disableDropdown,
        };
    },
});
