import { reactive } from 'vue';
import { store } from '@/decorators/store';
import Location from '@/domain/Location';

export enum Permissions {
    'ADMINISTRATOR' = 1,
    'RECEIVING_NEW' = 2,
    'RECEIVING_EXISTING' = 3,
    'SHIPPING_NEW' = 4,
    'SHIPPING_EXISTING' = 5,
    'INVENTORY_STOCK_AUDIT_CORRECTION' = 6,
    'INVENTORY_STOCK_AUDIT_COUNT' = 7,
    'INVENTORY_ADD_TAG' = 8,
    'INVENTORY_UPDATE_TAG' = 9,
    'INVENTORY_REPORTS' = 10,
    'INVENTORY_TRANSFER' = 11,
    'DOES_NOT_REQUIRE_CONTAINER_CONFIG' = 12,
    'FOUND_PARTS_NEW' = 13,
    'FOUND_PARTS_EXISTING' = 14,
    'ASSEMBLE_ITEMS' = 15,
    // should there be inventory stock audit balance?
}

type State = {
    username: string | null;
    appName: string | null;
    authenticated: boolean;
    location: Location | null;
    permissions: Array<number>;
};

@store
export default class ProfileStore {
    // state
    private state = reactive<State>({
        username: null,
        appName: null,
        authenticated: false,
        location: null,
        permissions: [
            // temporary to get things to work
            Permissions.ADMINISTRATOR,
        ],
    });

    get authenticated(): boolean {
        return this.state.authenticated;
    }

    get username(): string | null {
        return this.state.username;
    }

    get appName(): string | null {
        return this.state.appName;
    }

    get location(): Location | null {
        return this.state.location;
    }

    set location(value: Location | null) {
        this.state.location = value;
    }

    get permissions(): Array<number> {
        return this.state.permissions;
    }

    set permissions(value: Array<number>) {
        this.state.permissions = value;
    }

    public initProfile(username: string, appName: string) {
        this.state.authenticated = true;
        this.state.username = username;
        this.state.appName = appName;
    }

    public clearProfile() {
        this.state.authenticated = false;
        this.state.username = null;
        this.state.appName = null;
        this.state.location = null;
        this.state.permissions = [];
    }

    get isAdministrator(): boolean {
        return this.state.permissions.includes(Permissions.ADMINISTRATOR);
    }

    get isReceivingNew(): boolean {
        return this.state.permissions.includes(Permissions.RECEIVING_NEW);
    }

    get isReceivingExisting(): boolean {
        return this.state.permissions.includes(Permissions.RECEIVING_EXISTING);
    }

    get isShippingNew(): boolean {
        return this.state.permissions.includes(Permissions.SHIPPING_NEW);
    }

    get isShippingExisting(): boolean {
        return this.state.permissions.includes(Permissions.SHIPPING_EXISTING);
    }

    // tslint:disable-next-line:max-line-length
    get isInventoryStockAuditCorrection(): boolean {
        return this.state.permissions.includes(Permissions.INVENTORY_STOCK_AUDIT_CORRECTION);
    }

    get isInventoryStockAuditCount(): boolean {
        return this.state.permissions.includes(Permissions.INVENTORY_STOCK_AUDIT_COUNT);
    }

    get isInventoryAddTag(): boolean {
        return this.state.permissions.includes(Permissions.INVENTORY_ADD_TAG);
    }

    get isInventoryUpdateTag(): boolean {
        return this.state.permissions.includes(Permissions.INVENTORY_UPDATE_TAG);
    }

    get isInventoryReports(): boolean {
        return this.state.permissions.includes(Permissions.INVENTORY_REPORTS);
    }

    get isInventoryTransfer(): boolean {
        return this.state.permissions.includes(Permissions.INVENTORY_TRANSFER);
    }

    get isInventoryAssembleItem(): boolean {
        return this.state.permissions.includes(Permissions.ASSEMBLE_ITEMS);
    }

    get doesNotRequireContainerConfig(): boolean {
        return this.state.permissions.includes(Permissions.DOES_NOT_REQUIRE_CONTAINER_CONFIG);
    }
}
