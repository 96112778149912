import ShippingApiService from '@/modules/floortrak/services/api/ShippingApiService';
import { useNotification } from '@/composable/useNotifications';
import Shipment from '@/domain/Shipment';
import ShipmentDetails from '@/domain/ShipmentDetails';
import TrackedItem from '@/domain/TrackedItem';
import ProductionPartLoadInventory from '@/domain/ProductionPartLoadInventory';

export default class ShippingService {
    private shippingApiService = new ShippingApiService();

    private notification = useNotification();

    public async updateExistingShipment(shipmentToUpdate: Shipment) : Promise<{ shipment?: Shipment }> {
        const { success, data } = await this.shippingApiService.updateExistingShipment(shipmentToUpdate.toDTO());
        return { shipment: success ? data : undefined };
    }

    public async createNewPickedShipment(shipment: Shipment): Promise<{ shipmentId?: number }> {
        const { success, data } = await this.shippingApiService.createNewPickedShipment(shipment.toDTO());
        return { shipmentId: success ? data : undefined };
    }

    public async lookupShipmentByTransactionNumberAndFromLocation(transactionId: number, fromLocationId: number) : Promise<{ success: boolean, shipment?: Shipment, message?: string }> {
        const response = await this.shippingApiService.lookupShipmentByTransactionNumberAndFromLocation(transactionId, fromLocationId);
        if (response.success) {
            return {
                success: true,
                shipment: response.data,
            };
        }
        return {
            success: false,
            message: response.message,
        };
    }

    public async moveTransactionsToPicked(shipmentId: number) : Promise<{ success: boolean, shipment?: Shipment }> {
        const { success, data } = await this.shippingApiService.moveTransactionsToPicked(shipmentId);
        const shipment = success ? data : undefined;

        return {
            success,
            shipment,
        };
    }

    public async updateShipments(shipmentsToUpdate: Array<ShipmentDetails>): Promise<boolean> {
        const response = await this.shippingApiService.updateShipments(shipmentsToUpdate);
        return response.success;
    }

    public async getPickPlanForShipment(shipmentId: number): Promise<Array<TrackedItem>> {
        const response = await this.shippingApiService.getPickPlanForShipment(shipmentId);
        return response.success ? response.data : [];
    }

    public async getProductionPartLoadInventoryForDirectedPick(trackedItemIds: Array<number>): Promise<Array<ProductionPartLoadInventory>> {
        const response = await this.shippingApiService.getProductionPartLoadInventoryForDirectedPick(trackedItemIds);
        return response.success ? response.data : [];
    }

    public async moveProductionPartLoadInventoriesToHistory(transactionId: number): Promise<boolean> {
        const response = await this.shippingApiService.moveProductionPartLoadInventoriesToHistory(transactionId);
        return response.success;
    }
}
