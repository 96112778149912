import ProfileStore from '@/modules/floortrak/store/modules/ProfileStore';
import ShippingStore from './modules/ShippingStore';

export class FloorTrakStore {
    profileStore = new ProfileStore();

    shippingStore = new ShippingStore();

    private static instance: FloorTrakStore;

    public static getInstance(): FloorTrakStore {
        if (!FloorTrakStore.instance) {
            FloorTrakStore.instance = new FloorTrakStore();
        }

        return FloorTrakStore.instance;
    }

    public static isInitialized() {
        return Boolean(FloorTrakStore.instance);
    }
}

const floorTrakStore = FloorTrakStore;
export default floorTrakStore;
