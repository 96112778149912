import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import ShipmentDTO from '@/modules/floortrak/domain/jigsaw/dto/ShipmentDTO';
import Shipment from '@/domain/Shipment';
import ShipmentDetails from '@/domain/ShipmentDetails';
import TrackedItem from '@/domain/TrackedItem';
import Item from '@/domain/Item';
import ProductionPartLoadInventory from '@/domain/ProductionPartLoadInventory';

export default class ShippingApiService {
    public async lookupShipmentByTransactionNumberAndFromLocation(transactionNumber: number, fromLocationId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shipping/transaction-lookup`,
            params: { transactionId: transactionNumber, fromLocationId },
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async save(dto: ShipmentDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping`,
            data: dto,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateExistingShipment(dto: ShipmentDTO): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping`,
            data: dto,
        });

        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async createNewPickedShipment(dto: ShipmentDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/shipping/picked-shipment`,
            data: dto,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async moveTransactionsToPicked(shipmentId: number): Promise<DataAccessResponse<Shipment>> {
        const response: AxiosResponse<DataAccessResponse<Shipment>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/move-transactions-to-picked`,
            params: { shipmentId },
        });
        return new DataAccessResponseClass<Shipment>(response, Shipment).response;
    }

    public async updateShipments(shipmentsToUpdate: Array<ShipmentDetails>): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/update-shipments`,
            data: shipmentsToUpdate,
        });
        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getPickPlanForShipment(shipmentId: number): Promise<DataAccessResponse<Array<TrackedItem>>> {
        const response: AxiosResponse<DataAccessResponse<Array<TrackedItem>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shipping/pick-plan/${shipmentId}`,
        });

        // we need to initialize the Item inside of TrackItem so it has all of the class properties
        const darResponse = new DataListAccessResponseClass<TrackedItem>(response, TrackedItem).response;
        darResponse.data.forEach((d) => {
            d.item = new Item(d.item);
        });
        return darResponse;
    }

    public async getProductionPartLoadInventoryForDirectedPick(trackedItemIds: Array<number>): Promise<DataAccessResponse<Array<ProductionPartLoadInventory>>> {
        const response: AxiosResponse<DataAccessResponse<Array<ProductionPartLoadInventory>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/shipping/get-floor-locations`,
            params: { trackedItemIds },
        });

        return new DataListAccessResponseClass<ProductionPartLoadInventory>(response, ProductionPartLoadInventory).response;
    }

    public async moveProductionPartLoadInventoriesToHistory(transactionId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/shipping/move-to-history/${transactionId}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
