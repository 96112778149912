
import {
    computed, defineComponent, reactive, PropType, onMounted, WritableComputedRef,
} from 'vue';
import Shipment from '@/domain/Shipment';
import Transaction from '@/domain/Transaction';
import Location from '@/domain/Location';
import Carrier from '@/domain/Carrier';
import Dock from '@/domain/Dock';
import TrailerType from '@/domain/TrailerType';
import TrailerTypeService from '@/services/TrailerTypeService';
import { CoreStore } from '@/store/CoreStore';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import useModelWrapper from '@/composable/useModelWrapper';
import { ValidationResult } from '@/validation/types';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import DockService from '@/services/DockService';
import RouteConfigService from '@/services/RouteConfigService';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    trailerTypes: Array<TrailerType>;
    shipToLocations: Array<Location>;
    loading: boolean;
    docks: Array<Dock>;
};

export default defineComponent({
    name: 'shipping-header',
    components: {
        DropdownAutocompleteSingleSelect,
    },
    props: {
        // will be the active transaction passed in as `v-model`
        modelValue: {
            type: Transaction,
            required: true,
        },
        // will be the active shipment passed in as `v-model:shipment`
        modelShipment: {
            type: Shipment,
            required: true,
        },
        shipmentIsReadonly: {
            type: Boolean,
            default: false,
        },
        validationResult: {
            type: Object as PropType<ValidationResult<Shipment>>,
            default: undefined,
        },
        activeIndex: {
            type: Number,
            default: undefined,
        },
        carriers: {
            type: Array as PropType<Carrier[]>,
            required: true,
        },
    },
    emits: ['update:modelValue', 'update:modelShipment'],
    setup(props, context) {
        const routeConfigService = new RouteConfigService();
        const state = reactive<State>({
            trailerTypes: [],
            shipToLocations: [],
            loading: true,
            docks: [],
        });

        const transaction: WritableComputedRef<Transaction> = useModelWrapper(props, context, 'modelValue');
        const shipment: WritableComputedRef<Shipment> = useModelWrapper(props, context, 'modelShipment');
        const { userLocation } = CoreStore.getInstance().profileStore;

        const { confirm } = useDialogBox();
        const { configStore } = CoreStore.getInstance();
        const trailerService = new TrailerTypeService();
        const dockService = new DockService();

        const toLocationErrorText = computed(() => (props.validationResult?.model.transactions?.[(props.activeIndex as number)]?.toLocation));

        const partnerRefErrorText = computed(() => props.validationResult?.model.transactions?.[(props.activeIndex as number)]?.partnerReferenceNumber);

        state.trailerTypes = configStore.trailerTypes;
        state.shipToLocations = userLocation.availableShipToLocations;

        onMounted(async () => {
            state.docks = await dockService.getInServiceShippingDocksByLocationId(userLocation.id);
            if (state.docks.length === 1 && !props.shipmentIsReadonly) {
                shipment.value.dock = state.docks[0];
            }
            state.loading = false;
        });

        function updateCarrier(carrier: Carrier | null) {
            if (carrier) {
                shipment.value.carrier = carrier;
                shipment.value.carrierId = carrier.id;
            } else {
                shipment.value.carrierId = 0;
                shipment.value.carrier = new Carrier();
            }
        }

        function updateDock(dock: Dock | null) {
            if (dock) {
                shipment.value.dock = dock;
            } else {
                shipment.value.dock = new Dock();
            }
        }

        function updateTrailerType(trailerType: TrailerType) {
            if (trailerType) {
                shipment.value.trailerType = trailerType;
            } else {
                shipment.value.trailerType = new TrailerType();
            }
        }

        async function trySetPreferredTrailerType() {
            if (shipment.value.trailerType?.id) {
                // don't automatically change the trailer type if its already been set
                return;
            }

            state.loading = true;
            let trailerType: TrailerType | undefined;
            if (transaction.value.toLocation?.id) {
                trailerType = await trailerService.getPreferredTrailerTypeForRoute(transaction.value.fromLocation.id, transaction.value.toLocation.id);
            }
            updateTrailerType(trailerType ?? configStore.preferredTrailerType);
            state.loading = false;
        }

        async function trySetInventoryCategory() {
            state.loading = true;
            if (transaction.value.toLocationId > 0) {
                const inventoryCategories = await routeConfigService.getAllShipFromInventoryCategoriesInRouteConfig(transaction.value.fromLocationId, transaction.value.toLocationId);
                if (inventoryCategories.length === 1) {
                    transaction.value.inventoryCategory = inventoryCategories[0];
                    transaction.value.setInventoryCategoryDescriptionForTruckCard(
                        transaction.value.inventoryCategory?.description,
                    );
                } else {
                    transaction.value.setInventoryCategoryDescriptionForTruckCard(undefined);
                    await confirm({
                        title: getTranslation('core.validation.adminAndPlanningRequired'),
                        message: getTranslation('core.validation.multipleShipFromCategoriesExistShipFromAandP'),
                    });
                    transaction.value.clearTransactionLines();
                    transaction.value.toLocation = new Location();
                }
            } else {
                transaction.value.setInventoryCategoryDescriptionForTruckCard(undefined);
            }
            state.loading = false;
        }

        async function selectToLocation(location: Location) {
            if (location) {
                transaction.value.toLocation = new Location(location);
                await trySetPreferredTrailerType();
                await trySetInventoryCategory();
            } else {
                transaction.value.clearTransactionLines();
                transaction.value.toLocation = new Location();
            }
        }

        async function beforeClearToLocation(): Promise<boolean> {
            const response = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.validation.clearingDestinationWillClearContainersAndQuantities'),
            });
            if (response) {
                transaction.value.clearTransactionLines();
                transaction.value.toLocation = new Location();
            }
            return response;
        }

        return {
            transaction,
            shipment,
            state,
            toLocationErrorText,
            partnerRefErrorText,
            updateCarrier,
            updateDock,
            updateTrailerType,
            selectToLocation,
            beforeClearToLocation,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
