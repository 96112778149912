import TrackedItem from '@/domain/TrackedItem';
import Transaction from '@/domain/Transaction';
import TagScanHandler from './TagScanHandler';

export default class FloortrakShippingTagScanHandler extends TagScanHandler {
    private transaction: Transaction;
    private barcodesAlreadyScanned: Array<string>;
    private action: (tag: TrackedItem) => void;

    constructor(transaction: Transaction, barcodesAlreadyScanned: Array<string>, action: (tag: TrackedItem) => void) {
        super();
        this.transaction = transaction;
        this.barcodesAlreadyScanned = barcodesAlreadyScanned;
        this.action = action;
    }

    protected override async fetchTags(): Promise<Array<TrackedItem> | undefined> {
        let result: Array<TrackedItem> | undefined = new Array<TrackedItem>();

        if (!this.transaction.toLocationId) {
            this.notification.showWarning('Please select destination location before adding items');
        } else {
            const trackedItems = await this.trackedItemService.getTrackedItemsInRouteConfig(this.scannerInput, this.transaction.fromLocationId, this.transaction.toLocationId);
            result = trackedItems;
        }

        return result;
    }

    protected override handleScan(returnedTags: Array<TrackedItem>): Array<string> {
        const duplicates = this.scannerInput.filter((b) => this.barcodesAlreadyScanned.includes(b));
        returnedTags.filter((tag) => !duplicates.includes(tag.barcode)).forEach((tag) => this.action(tag));
        return duplicates;
    }

    protected override handleScanManagedWarehouse(): Array<string> {
        return [];
    }
}
