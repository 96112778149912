import { reactive } from 'vue';
import { useNotification } from '@/composable/useNotifications';
import Shipment from '@/domain/Shipment';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import Location from '@/domain/Location';
import Item from '@/domain/Item';
import ProfileStore from '@/store/modules/ProfileStore';
import { CoreStore } from '@/store/CoreStore';
import { TransactionStatus } from '@/domain/TransactionStatus';
import { getTranslation } from '@/services/TranslationService';

type State = {
    shipment: Shipment;
    activeIndex: number;
    key: number;
};

export default class ShippingStore {
    private profileStore: ProfileStore;

    private notification = useNotification();

    private state = reactive<State>({
        shipment: new Shipment(),
        activeIndex: 0,
        key: 0,
    });

    constructor() {
        this.profileStore = CoreStore.getInstance().profileStore;
    }

    get transactionArray(): Array<Transaction> {
        // @ts-ignore
        return this.state.shipment.transactions;
    }

    get shipment(): Shipment {
        // @ts-ignore
        return this.state.shipment;
    }

    set shipment(value: Shipment) {
        this.state.shipment = value;
    }

    get activeIndex(): number {
        return this.state.activeIndex;
    }

    set activeIndex(value: number) {
        this.state.activeIndex = value;
    }

    get activeTransaction(): Transaction {
        // @ts-ignore
        return this.state.shipment.transactions[this.state.activeIndex];
    }

    // TODO - need to figure out why vue won't re-render w/out key

    set activeTransaction(value: Transaction) {
        this.state.key++;
        this.state.shipment.transactions[this.state.activeIndex] = value;
    }

    get key(): number {
        return this.state.key;
    }

    public setDefaultFromLocation(userLocation: Location) {
        this.state.shipment.fromLocation = userLocation;
    }

    public getIfContainersExistInTransactionArray(): boolean {
        for (const transaction of this.state.shipment.transactions) {
            if (transaction.transactionLines.length > 0) {
                return true;
            }
        }
        return false;
    }

    get isExisting(): boolean {
        return !!this.shipment.id;
    }

    get readonly(): boolean {
        const readonlyTransactions = this.state.shipment.transactions.filter((tr) => tr.status >= TransactionStatus.HELD);
        return readonlyTransactions.length > 0;
    }

    get canShipNow(): boolean {
        const nonShippableTrs = this.state.shipment.transactions.filter((tr) => tr.hasShipped);
        return nonShippableTrs.length === 0;
    }

    public resetTransactionArray(): void {
        this.state.shipment = new Shipment();
        this.state.activeIndex = 0;

        if (this.profileStore.isUserLocationSet) {
            this.state.shipment.fromLocation = new Location(this.profileStore.userLocation);
        } else {
            throw new Error(getTranslation('core.validation.userLocationMustBeSelectedBeforeStartingShipment'));
        }
    }

    public addNewTransaction() {
        const newTransaction = new Transaction();
        this.setDefaultFromLocation(this.profileStore.userLocation);
        newTransaction.fromLocation = this.state.shipment.fromLocation;
        newTransaction.toLocation = new Location();
        this.addTransactionToShipment(newTransaction);
    }

    public addExistingTransaction(transaction: Transaction) {
        const existingTransaction = new Transaction(transaction);
        this.addTransactionToShipment(existingTransaction);
    }

    private addTransactionToShipment(transaction: Transaction) {
        const nextIndex = this.state.activeIndex + 1;
        const arrayLength = this.state.shipment.transactions.length;

        if (arrayLength === 0 || nextIndex === arrayLength) {
            this.state.shipment.transactions.push(transaction);
            this.state.activeIndex = this.state.shipment.transactions.length - 1;
        } else {
            this.state.shipment.transactions.splice(nextIndex, 0, transaction);
            this.state.activeIndex = nextIndex;
        }

        if (this.state.shipment.transactions.length > 1) {
            useNotification().showSuccess(`${getTranslation('core.common.stopAdded', (nextIndex + 1).toString()).toUpperCase()}`);
        }
    }

    public addItemManual(item: Item, quantity: number): void {
        this.state.shipment.transactions[this.state.activeIndex].addActualItemQty(item, quantity);
        this.notification.show({
            head: getTranslation('core.validation.success').toUpperCase(),
            message: `${getTranslation('core.common.added')} : ${item.displayName} (${quantity})`,
            time: 750,
            type: 'success',
        });
    }

    public removeLine(line: TransactionLine): void {
        const { transactionLines } = this.state.shipment.transactions[this.state.activeIndex];
        const index = transactionLines.findIndex((thisLine) => thisLine.item.id === line.item.id);
        if (index > -1) {
            transactionLines.splice(index, 1);
        }
        useNotification().show({
            message: `${getTranslation('core.common.removed').toUpperCase()} ${line.item.displayName}`,
            type: 'success',
            time: 750,
        });
    }
}
